header {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 35px;
  background: #ffffff;
  position: absolute;
  top: 0;
  width: 100%;

  .head-left-block {
    flex-shrink: 0;
    flex-grow: 0;
    width: 200px;
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 41px;
    color: $colorBlue;
  }

  .head-title {
    flex-grow: 1;
    font-weight: 600;
    font-size: 16px;
    color: #B4B4B4;

    a{
      color: #656688;
      text-decoration: none;
    }
  }

  .head-right-block {
    flex-grow: 0;
    flex-shrink: 0;
    width: 100px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .ico-btn {
      height: 45px;
      cursor: pointer;
    }
  }
}