.in-dev-info__bg{
    position: fixed;
    width: 100%;
    height: calc(100vh - 70px);
    background: rgba(0,0,0,0.3);
}

.in-dev-info__text{
    background: #FFFFFF;
    color: #000;
    padding: 50px;
    width: 200px;
    margin: 200px auto 0 auto;
    text-align: center;
    font-size: 20px;
    line-height: 150%;
}