#project-body{
  width: 100%;
  height: calc(100vh - 70px);
  pointer-events: none;
}

#all-projs {
  position: absolute;
  left: 15px;
  top: 15px;
  width: 45px;
  cursor: pointer;
  filter: saturate(0);
  transition: 250ms;

  &:hover {
    filter: saturate(1);
  }
}


.project-add-edit {
  overflow: auto;

  .pe-select {
    display: block;
  }

  .block {
    width: 750px;
    margin: 40px auto;
    padding-bottom: 50px;
    position: relative;

    .closer {
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 2;
      cursor: pointer;
      width: 20px;
    }
  }

  .flex {
    display: flex;

    .pe-input {
      flex-grow: 1;
    }

  }

  .pe-input {
    margin-top: 0;
  }

  .help-text {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }

  .btn{
    width: 160px;
  }
}

.projects-list {
  flex-wrap: wrap;
  overflow: auto;
  padding: 40px 80px;
  margin: auto;
}

.project-item {
  min-width: 350px;
  max-width: 450px;
  padding: 15px 0 20px 0;
  margin: 20px;

  .clocks-text {
    color: #787C84;
  }

  .clocks {
    flex-grow: 0;
    margin: 0 10px;
  }

  .header {
    a {
      text-decoration: none;
    }

    display: flex;
    align-items: center;
    border-bottom: 2px solid #E7EBF8;
    padding: 0 18px 10px 18px;
    margin-bottom: 10px;

    .title {
      flex-grow: 1;
      font-weight: 600;
      font-size: 15px;
    }

    .edit-link {
      width: 20px;
      text-align: right;
      flex-grow: 0;
    }
  }
}

.project-list-bottom {
  display: flex;
  align-items: center;
  padding: 0 18px;
}

.s-ico {
  .online, .offline {
    $s: 8px;
    width: $s;
    height: $s;
    border-radius: $s;
  }

  .online {
    background: $colorGreen;
  }

  .offline {
    background: #BFC4D2;
  }
}

.dev-status {
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 50%;

  .s-ico {
    width: 25px;
    flex-grow: 0;
  }

  .s-title {
    flex-grow: 1;
  }
}


#dev-serial-tt {
  position: absolute;
  background: #FFFFFF;
  color: $colorBlack;
  font-size: 14px;
  padding: 10px 15px;
  border: 1px solid #303642;
  display: none;
}