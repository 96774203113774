.switcher{
    $w: 28px;
    $h: 18px;
    $lw: 10px;
    $btnOffSize: 18px;
    $btnOnSize: $h;
    width: $w;
    height: $h;
    position: relative;
    cursor: pointer;
    .click-area{
        position: absolute;
        width: $w * 1.5;
        height: $h * 1.5;
        top: -$h * 0.25;
        left: -$w * 0.25;
    }
    .center-line{
        transition: 300ms background-color;
        position: absolute;
        height: $lw;
        width: $w;
        background: #7D7D7D;
        top: ($h - $lw)/2;
        border-radius: $lw;
    }
    .switch-circle{
        transition: 300ms;
        position: absolute;
        $s: $btnOffSize;
        width: $s;
        height: $s;
        border-radius: $s;
        background: #ADADAD;
        transform: translateY(($h - $btnOffSize)/2);
    }
    &.on{
        .center-line{
            background: #899CB1;
        }
        .switch-circle{
            width: $btnOnSize;
            height: $btnOnSize;
            border-radius: $btnOnSize;
            background: $colorAcent;
            transform: translateX($w - $btnOnSize) translateY(($h - $btnOnSize)/2);
        }
    }
}
