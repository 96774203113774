@import "vars";
@import "common";
@import "inputs";
@import "switcher";
@import "header";
@import "popup";
@import "projects";
@import "other";

@import "~mapbox-gl/dist/mapbox-gl.css";
@import '~@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
@import '~vue-select/dist/vue-select.css';
@import '~@fortawesome/fontawesome-free/css/all.css';

/// scrollbar experiments
*::-webkit-scrollbar {
  width: 4px;
}
*::-webkit-scrollbar-track {
  background: #E7EBF8;
}
*::-webkit-scrollbar-thumb {
  background: #b7c0d0;
}
*{
  scrollbar-color: #E7EBF8 #b7c0d0;
  scrollbar-width: thin;
}


#side-menu{
  padding-top: 15px;
  width: 210px;
  background: #ffffff;
  height: 100vh;
  position: absolute;
  left: 0;
  z-index: 9;
}

.side-menu__item{
  height: 60px;
  margin: 15px 0;
  display: flex;
  align-items: center;
  border-left: 10px solid #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #B4B4B4;
  transition: 150ms;
  cursor: pointer;

  img {
    filter: saturate(0);
    opacity: 0.9;
    margin: 0 15px;
  }

  &.active{
    img {
      filter: saturate(1);
    }
    border-color: $colorBlue;
    color: $colorBlue;
    background: rgba(0, 119, 255, 0.2);
  }

  &:hover{
    img {
      filter: saturate(0.7);
    }
    opacity: 0.7;
    border-color: $colorBlue;
    color: $colorBlue;
    background: rgba(0, 119, 255, 0.2);
  }
}



#side-menu-right{
  padding-top: 15px;
  width: 90px;
  background: #ffffff;
  height: 100vh;
  position: absolute;
  right: 0;
  z-index: 9;
}

.side-menu-right__item{
  height: 60px;
  margin: 15px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: center;
  justify-content: center;

  img {
    filter: saturate(0);
    opacity: 0.9;
    width: 40px;
    &.special{
      filter: saturate(1);
    }
  }

  &.active{
    img {
      filter: saturate(1);
    }
    border-color: $colorBlue;
    color: $colorBlue;
    background: rgba(0, 119, 255, 0.2);
  }

  &:hover{
    img {
      filter: saturate(0.7);
    }
    opacity: 0.7;
    border-color: $colorBlue;
    color: $colorBlue;
    background: rgba(0, 119, 255, 0.2);
  }
}

.map-marker{
  $s: 32px;
  width: $s;
  height: $s;
  transform: translate(-50%, -50%);
  border-radius: $s;
  text-align: center;
  padding: 6px;
  cursor: pointer;
  transition: opacity 200ms;
  &:hover{
    opacity: 0.7;
  }
  &.selected{
    border: 2px solid #0043ee;
  }
  img{
    height: $s;
    pointer-events: none;
  }
}