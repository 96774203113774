@mixin pe-input {
  display: block;
  border: 2px solid #E7EBF8;
  border-radius: 5px;
  box-sizing: border-box;
  color: #464B56;
  font-size: 15px;
  line-height: 19px;
  padding: 15px;
  background: transparent;
  &:focus{
    border-color: #a5a9ff;
    outline: none;
  }
}


input, textarea{
  @include pe-input;

  &::placeholder{
    color: $colorPlaceholder;
  }
}

.pe-input {
  position: relative;
  margin: 10px 0;

  &.in-table{
    margin: 0;
    input, textarea{
      padding: 7px 10px;
    }
  }

  input, textarea {
    width: 100%;
  }

  .ico-error, .ico-success, .show-pwd{
    position: absolute;
    right: 30px;
    margin-top: -36px;
  }

  .show-pwd {
    img {
      width: 25px;
      cursor: pointer;
    }
  }

  .ico-success, .ico-error {
    display: none;
  }

  $mEye: 30px;
  &.success {
    input, textarea{
      border-color: $colorGreen;
    }

    .ico-success {
      display: block;
    }

    .show-pwd {
      right: $mEye;
    }
  }

  &.error {
    //margin-bottom: 0;

    input, select, textarea{
      border-color: $colorRed;
    }

    .ico-success {
      display: none;
    }

    .ico-error {
      display: block;
    }

    .show-pwd {
      right: $mEye;
    }
  }

  .err-hint {
    color: $colorRed;
    font-size: 14px;
    line-height: 20px;
    margin-top: 5px;
    min-height: 20px;
  }
}

.pe-input-label{
  pointer-events: none !important;
  position: absolute;
  color: #8b98a7;
  top: 15px;
  left: 15px;
  transition: 200ms;
  &.active{
    top: -8px;
    left: 10px;
    font-size: 12px;
    background: #ffffff;
    padding: 0 5px;
  }
}
//////////////// Select ///////////////////

select{
  @include pe-input;
  //height: 44px; // только так не ратягивает
}

.pe-select{
  select{
    padding-right: 12px;
    width: 100%;
  }
  .placeholder{
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none !important;
    color: $colorPlaceholder;
    font-size: 16px;
    line-height: 22px;
    padding: 15px 0 0 10px;
  }
}

.vs__search {
  &::placeholder{
    color: #848484;
  }
}