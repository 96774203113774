
.popup-bg{
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  .block{
    .title{
      font-size: 18px;
      line-height: 25px;
    }
    .btns{
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn{
        min-width: 130px;
        padding: 10px 25px;
      }
    }
  }
}
