html, body{
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  color: $colorBlack;
}

#map{
  height: 100vh;
  width: 100%;
}

$hHeight: 70px;
header{
  height: $hHeight;
}

#cbody{
  position: absolute;
  height: calc(100vh - #{$hHeight});
  width: 100%;
  top: $hHeight;
  //border: #1d9916 1px solid;
  box-sizing: border-box;
  pointer-events: none;
  *{
    pointer-events: auto;
  }
  overflow: hidden;
}

.block{
  background: #ffffff;
  border-radius: 5px;
  color: $colorBlack;
  padding: 40px 40px 60px 40px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}

a{
  color: $colorBlue;
  &:hover{
    opacity: 0.8;
  }
}

h3, .h3{
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}

//fix stupid chrome
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $colorBlack;
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
  background-color: transparent !important;
  background-image: none !important;
  color: $colorBlack !important;
}
input:-internal-autofill-selected {
  background-color: transparent !important;
  background-image: none !important;
  color: $colorBlack !important;
}


.btn{
  color: #ffffff;
  //padding: 15px 25px;
  padding: 10px 20px;
  border-radius: 5px;
  background: $colorBlue;
  border: none;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  transition: color 300ms, background-color 300ms;
  &.narrow{
    padding: 9px 20px;
    font-size: 16px;
    line-height: 22px;
    border-width: 2px;
  }
  &:hover{
    color: rgba(255, 255, 255, 0.7);
  }
  &:focus{
    outline: none;
  }

  &.green{
    background: $colorGreen;
  }
  &.blue{
    background: $colorBlue;
  }
  &.gray{
    background: $colorPlaceholder;
  }

  &.outline-blue{
    border: 3px solid $colorBlue;
    color: $colorBlue;
    background: transparent;
    &:hover{
      color: #ffffff;
      background: $colorBlue;
    }
  }

  &.outline-red{
    border: 3px solid $colorRed;
    color: $colorRed;
    background: transparent;
    &:hover{
      color: #ffffff;
      background: $colorRed;
    }
  }

  &.inactive{
    opacity: 0.7;
    color: #f1f1f1;
    cursor: not-allowed;
  }

  &.red{
    background: $colorRed;
  }
}

.ico-btn{
  cursor: pointer;
}

.ico-btn-color{
  cursor: pointer;
  transition: 250ms;
  &:hover{
    opacity: 0.8;
  }
}

.modal-bg{
  top: 0;
  left: 0;
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.center-content{
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  //pointer-events: none !important;
  box-sizing: border-box;
}

 //hide mapbox logos
.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right{
  display: none !important;
}


.online, .offline{
  $s: 9px;
  width: $s;
  height: $s;
  border-radius: $s;
  &.online{
    background: $colorGreen;
  }
  &.offline{
    background: #ADADAD;
  }
}

.error-msg{
  color: $colorRed;
}

.table{
  width: 100%;
  border-collapse: collapse;

  thead td{
    font-size: 14px;
    line-height: 16px;
    color: #787C84;
  }
  td{
    font-size: 15px;
    border-spacing: 0;
    padding: 10px 15px;
    vertical-align: top;
    border: 1px solid #d2d2d2;
  }
}


.block.movable{
  //padding: 15px 25px;
  padding: 12px 15px;
  border-radius: 5px;
  .header{
    cursor: move;
    color: $colorBlue;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 2px solid #E7EBF8;
    .block-title{
      flex-grow: 1;
    }
    img.close{
      height: 18px;
      opacity: 0.5;
      transition: opacity 150ms;
      &:hover{
        opacity: 1;
      }
    }
  }
}


.flex {
  display: flex;
  .pe-input, .like-input {
    flex-grow: 1;
  }
}

.like-input{
  padding: 7px 10px;
  border: 2px solid #E7EBF8;
  border-radius: 5px;
  box-sizing: border-box;
  color: #464B56;
  font-size: 14px;
  line-height: 19px;
}

.f-row{
  margin: 0 -10px;
  .pe-input,  .like-input{
    margin: 0 10px 10px 10px;
  }
}

.vs__selected {
  background: transparent;
  border: none;
  border-radius: 0;
}

.vue-notification.my {
  font-size: 14px;
  padding: 10px;
  margin: 0 10px 10px;
}

.link-like {
  cursor: pointer;
  //text-decoration: underline;
  color: #416CF8;
  transition: opacity 200ms;

  &:hover {
    //text-decoration: none;
    opacity: 0.7;
  }
}